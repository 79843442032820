/* Bootstrap Variables */
/* Button */
.btn {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 35px 13px;
  transition: all 0.4s ease 0s;
  text-transform: uppercase;
  border-radius: 4px;
  gap: 10px;
}
.btn:focus {
  box-shadow: none;
}
.btn-sm {
  padding: 10px 25px 8px;
}
.btn-light {
  background-color: #FFFFFF;
}
.btn-primary {
  color: #FFFFFF;
}
.btn-secondary {
  color: #FFFFFF;
}
.btn-success {
  color: #FFFFFF;
}
.btn-info {
  color: #FFFFFF;
}
.btn-warning {
  color: #000000;
}
.btn-danger {
  color: #FFFFFF;
}
.btn-light {
  color: #000000;
}
.btn-dark {
  color: #FFFFFF;
}
.btn-social {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 10px;
}
.btn-google {
  color: #F14336;
  border-color: #E5E5E5;
  background-color: #F7F7F7;
}
.btn-google:hover {
  background-color: #e4e4e4;
}
.btn-facebook {
  color: #FFFFFF;
  background-color: #37538D;
}
.btn-facebook:hover {
  background-color: #2c4371;
}
.btn-twitter {
  color: #FFFFFF;
  background-color: #1DA1F2;
}
.btn-twitter:hover {
  background-color: #0d8ddc;
}

/* Outline Default Hover */
[class*=btn-outline] {
  border: 1px solid;
}
[class*=btn-outline]:hover {
  background-color: transparent;
}

/* Color Variation Hover */
.btn-hover-primary:hover {
  border-color: #5463FF;
  background-color: #5463FF;
  color: #FFFFFF;
}

.btn-hover-secondary:hover {
  border-color: #6c757d;
  background-color: #6c757d;
  color: #FFFFFF;
}

.btn-hover-success:hover {
  border-color: #198754;
  background-color: #198754;
  color: #FFFFFF;
}

.btn-hover-info:hover {
  border-color: #0dcaf0;
  background-color: #0dcaf0;
  color: #FFFFFF;
}

.btn-hover-warning:hover {
  border-color: #ffc107;
  background-color: #ffc107;
  color: #000000;
}

.btn-hover-danger:hover {
  border-color: #DC3545;
  background-color: #DC3545;
  color: #FFFFFF;
}

.btn-hover-light:hover {
  border-color: #F3F3F9;
  background-color: #FFFFFF;
  color: #000000;
}

.btn-hover-dark:hover {
  border-color: #221F1E;
  background-color: #221F1E;
  color: #FFFFFF;
}